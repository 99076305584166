@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  .animate-blink {
    animation: blink 1s infinite;
  }

  .bg-footer{
    background-image: url('https://squarebric-media-files.squarebric.com/sivaldevops/assets/footer1.png');
  }