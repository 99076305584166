.bg-traffic {
  background-image: url("https://squarebric-media-files.squarebric.com/sivaldevops/assets/traffic1.png");
  position: relative;
}

.bg-network {
  background-image: url("https://squarebric-media-files.squarebric.com/sivaldevops/assets/traffic2.png");
}

/* Trafficsection6.css */
.traffic-image-container {
  width: 100%;
  overflow: hidden; /* Hide overflowing images */
  position: relative; /* Set relative positioning */
}

.traffic-images-slider {
  display: flex;
  transition: transform 0.5s ease; /* Smooth transition for sliding effect */
}

.traffic-image {
  width: 90%; /* Each image takes full width */
  height: 90%; /* Ensure images fill the height */
  object-fit: cover; /* Cover the area without distortion */
  flex: 0 0 100%; /* Prevent images from shrinking */
  border-radius: 30px;
}

@media screen and (min-width: 768px) {
  .traffic-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    flex: 0 0 100%;
    border-radius: 30px;
  }
}

@media screen and (min-width: 640px) {
  .traffic-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    flex: 0 0 100%;
    border-radius: 30px;
  }
}
